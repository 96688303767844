import React, {
    useState,
  } from 'react'  
import update from 'immutability-helper'
import { throttle } from 'lodash'
import {
    TextField,
    Popover,
    FormLayout,
    ColorPicker,
    RangeSlider,
    LegacyStack
  } from "@shopify/polaris"
import FieldTitle from '../../../../components/fieldTitle'
import CardDivider from '../../../../components/cardDivider'
function hslToHex(h, s, l) {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = n => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
}

function ButtonsTab(props) {
    const { state, setState } = props;
    const [ctaBackgroundColorPopoverVisible, setCtaBackgroundColorPopoverVisible] = useState(false)
    const [ctaBackgroundColorHsl, setCtaBackgroundColorHsl] = useState({
        hue: 131,
        saturation: 86,
        brightness: 34,
        alpha: 1,
      })
    const [ctaButtonTextColorPopoverVisible, setCtaButtonTextColorPopoverVisible] = useState(false)
    const [ctaButtonTextColorHsl, setCtaButtonTextColorHsl] = useState({
        hue: 0,
        saturation: 0,
        brightness: 100,
        alpha: 1,
      })
    const [ctaButtonBorderColorPopoverVisible, setCtaButtonBorderColorPopoverVisible] = useState(false)
    const [ctaButtonBorderColorHsl, setCtaButtonBorderColorHsl] = useState({
        hue: 0,
        saturation: 0,
        brightness: 100,
        alpha: 1,
      })

    const ctaBackgroundColorActivatorButton = (
        <TextField 
          label="Customize button color"
          onFocus={() => setCtaBackgroundColorPopoverVisible(true)} 
          value={state.ctaBackgroundColor}
          onChange={(val) => setState(update(state, { ctaBackgroundColor: { $set: val }})) }
          connectedRight={
            <div 
              style={{ 
                borderRadius: 5,
                cursor: 'pointer',
                height: 36, 
                width: 40, 
                backgroundColor: state.ctaBackgroundColor 
              }}
              onClick={() => setCtaBackgroundColorPopoverVisible(true)} 
            />
          }
          helpText={
            <span>
              Optional override to the default button color
            </span>
          }
        />
      )
    
    const onCtaBackgroundColorChange = (updatedColor) => {
        const color = {
          hue: updatedColor.hue || ctaBackgroundColorHsl.hue || 0,
          saturation: updatedColor.saturation || ctaBackgroundColorHsl.saturation || 0,
          brightness: updatedColor.brightness || ctaBackgroundColorHsl.brightness || 0,
          alpha: 1,
        }
        const hex = hslToHex(
          color.hue, 
          color.saturation * 100, 
          color.brightness * 100,
        )
    
        console.log('Color changed: ', color, hex)
    
        setCtaBackgroundColorHsl(color)
        setState(update(state, { ctaBackgroundColor: { $set: hex }})) 
      }
    const onCtaBackgroundColorChangeThrottled = throttle(onCtaBackgroundColorChange, 250)

    const ctaButtonTextColorActivatorButton = (
      <TextField
        label="Customize button text color"
        onFocus={() => setCtaButtonTextColorPopoverVisible(true)}
        value={state.ctaButtonTextColor}
        onChange={(val) => setState(update(state, { ctaButtonTextColor: { $set: val }})) }
        connectedRight={
          <div
            style={{
              borderRadius: 5,
              cursor: 'pointer',
              height: 36,
              width: 40,
              backgroundColor: state.ctaButtonTextColor
            }}
            onClick={() => setCtaButtonTextColorPopoverVisible(true)}
          />
        }
        helpText={
          <span>
            Optional override to the default button text color
          </span>
        }
      />
    )

    const onCtaButtonTextColorChange = (updatedColor) => {
        const color = {
          hue: updatedColor.hue || ctaButtonTextColorHsl.hue || 0,
          saturation: updatedColor.saturation || ctaButtonTextColorHsl.saturation || 0,
          brightness: updatedColor.brightness || ctaButtonTextColorHsl.brightness || 0,
          alpha: 1,
        }
        const hex = hslToHex(
          color.hue, 
          color.saturation * 100, 
          color.brightness * 100,
        )
    
        console.log('Color changed: ', color, hex)
    
        setCtaButtonTextColorHsl(color)
        setState(update(state, { ctaButtonTextColor: { $set: hex }})) 
      }
    const onCtaButtonTextColorChangeThrottled = throttle(onCtaButtonTextColorChange, 250)

    const ctaButtonBorderColorActivatorButton = (
      <TextField
        label="Customize button border color"
        onFocus={() => setCtaButtonBorderColorPopoverVisible(true)}
        value={state.ctaButtonBorderColor}
        onChange={(val) => setState(update(state, { ctaButtonBorderColor: { $set: val }})) }
        connectedRight={
          <div
            style={{
              borderRadius: 5,
              cursor: 'pointer',
              height: 36,
              width: 40,
              backgroundColor: state.ctaButtonBorderColor
            }}
            onClick={() => setCtaButtonBorderColorPopoverVisible(true)}
          />
        }
        helpText={
          <span>
            Optional override to the default button border color
          </span>
        }
      />
    )

    const onCtaButtonBorderColorChange = (updatedColor) => {
      const color = {
        hue: updatedColor.hue || ctaButtonBorderColorHsl.hue || 0,
        saturation: updatedColor.saturation || ctaButtonBorderColorHsl.saturation || 0,
        brightness: updatedColor.brightness || ctaButtonBorderColorHsl.brightness || 0,
        alpha: 1,
      }
      const hex = hslToHex(
        color.hue,
        color.saturation * 100,
        color.brightness * 100,
      )

      console.log('Color changed: ', color, hex)

      setCtaButtonBorderColorHsl(color)
      setState(update(state, { ctaButtonBorderColor: { $set: hex }}))
    }

    const onCtaButtonBorderColorChangeThrottled = throttle(onCtaButtonBorderColorChange, 250)
    
    const suffixStyles = {
        minWidth: '24px',
        textAlign: 'right',
      };

    return (
      <FormLayout>
          <LegacyStack wrap={false} alignment="trailing">
            <LegacyStack.Item fill>
              <TextField
                label="CTA button custom text"
                placeholder="Add to cart"
                onChange={(val) => setState(update(state, { cta: { $set: val }})) }
                value={state.cta}
                helpText={
                  <span>
                    Optional override to the default “Add to cart” text
                  </span>
                }
              />
            </LegacyStack.Item>
          </LegacyStack>
          <CardDivider />
          <FieldTitle title="Colors" />
          <Popover
            active={ctaBackgroundColorPopoverVisible}
            activator={ctaBackgroundColorActivatorButton}
            onClose={() => setCtaBackgroundColorPopoverVisible(false)}
          >
            <ColorPicker 
              color={ctaBackgroundColorHsl}
              onChange={onCtaBackgroundColorChangeThrottled}
            />
          </Popover>

          <Popover
            active={ctaButtonTextColorPopoverVisible}
            activator={ctaButtonTextColorActivatorButton}
            onClose={() => setCtaButtonTextColorPopoverVisible(false)}
          >
            <ColorPicker
              color={ctaButtonTextColorHsl}
              onChange={onCtaButtonTextColorChangeThrottled}
            />
          </Popover>

          <Popover
            active={ctaButtonBorderColorPopoverVisible}
            activator={ctaButtonBorderColorActivatorButton}
            onClose={() => setCtaButtonBorderColorPopoverVisible(false)}
          >
            <ColorPicker
              color={ctaButtonBorderColorHsl}
              onChange={onCtaButtonBorderColorChangeThrottled}
            />
          </Popover>
          <CardDivider />
          <FieldTitle title="Button border" />
          <RangeSlider
            label="Button border radius"
            prefix={<p>px</p>}
            suffix={<p style={suffixStyles}>{state.buttonBorderRadius}</p>}
            value={state.buttonBorderRadius}
            onChange={(val) => setState(update(state, { buttonBorderRadius: { $set: val }})) }
            output
          />
          <RangeSlider
            label="Button border height"
            prefix={<p>px</p>}
            suffix={<p style={suffixStyles}>{state.buttonBorderWidth}</p>}
            value={state.buttonBorderWidth}
            onChange={(val) => setState(update(state, { buttonBorderWidth: { $set: val }}))}
            output
          />
      </FormLayout>
    );
}

export default ButtonsTab;